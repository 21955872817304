<template>
  <div class="grid nav">
    <div class="grid logo">
      <!-- <div class="placeholder"></div> -->
      <img src="/icon_32.png">
      <span>Ombrelleria</span>
    </div>
    <div class="grid actions hidden-xs">
      <!-- <router-link to="hotel">Hotel</router-link> -->
      <!-- <router-link to="hotel">Spiaggia</router-link> -->
      <!-- <router-link to="hotel">Lido</router-link> -->
      <a href="#" v-scroll-to="{el: '#welcome', duration: 500, easing: 'ease-in'}">Aspettative</a>
      <a href="#" v-scroll-to="{el: '#functions', duration: 500, easing: 'ease-in'}">Funzioni</a>
      <span></span>
      <a href="#" v-scroll-to="{el: '#faq', duration: 500, easing: 'ease-in'}">FAQ/Contatti</a>
      <a href="https://blog.ombrelleria.com/tag/help" target="new">Aiuto</a>
    </div>
    <!-- <div class="grid lang"></div> -->
    <div class="grid" style="grid-auto-flow: column; grid-column-gap: 20px; align-items: center;">
      <div class="grid login">
        <a href="https://console.ombrelleria.com" class="btn red">Login</a>
      </div>
      <div class="grid social">
        <a href="https://www.facebook.com/ombrelleria" target="new"><font-awesome-icon :icon="['fab', 'facebook-square']" /></a>
        <a href="https://www.instagram.com/ombrelleria" target="new"><font-awesome-icon :icon="['fab', 'instagram']" /></a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Navbar',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
