<template>
    <div class="site home">

        <div class="grid header h100">
            <navbar></navbar>
            <div class="grid featured">
                <div class="grid text">
                    <div class="grid content">
                        <h1>{{content.Header_title}}</h1>
                        <div v-html="content.Header_content"></div>
                        <div class="grid cta">
                            <!-- <span class="primary"><a href="https://dashboard.ombrelleria.com" class="btn red">Provalo subito</a></span> -->
                            <span class="primary" @click="gevent('cta1')"><span class="btn red">Provalo subito</span></span>
                            <span class="secondary"><a href="#" v-scroll-to="{el: '#welcome', duration: 500, easing: 'ease-in'}">come mi aiuta?</a></span>
                        </div>
                    </div>
                </div>
                <div class="grid img">
                    <img class="hidden-xs" src="/img/header-home.png">
                    <div class="background visible-xs" style="background-image: url('/img/header-home.png');"></div>
                </div>
            </div>
        </div>

        <div class="grid welcome" id="welcome">
            <div class="grid title">
                <span class="quest">come mi aiuta?</span>
                <span class="answer">In poche parole:</span>
            </div>
            <div class="grid shader"></div>
            <div class="grid content">
                <div class="grid features">
                    <div class="grid feature">
                        <!-- <img src="/img/feature-one.png"> -->
                        <div class="grid balls">
                            <div class="grid ball red"></div>
                            <div class="grid ball"></div>
                            <div class="grid ball"></div>
                        </div>
                        <h2>{{content.Feature_one_title}}</h2>
                        <div v-html="content.Feature_one_content"></div>
                    </div>
                    <div class="grid feature">
                        <!-- <img src="/img/feature-two.png"> -->
                        <div class="grid holiday">
                            <div class="grid elipse"></div>
                            <div class="grid text">
                                <span>{{holiday.start}} - {{holiday.end}}</span>
                            </div>
                            <div class="grid icon">
                                <font-awesome-icon :icon="['fas', 'search']" />
                            </div>
                        </div>
                        <h2>{{content.Feature_two_title}}</h2>
                        <div v-html="content.Feature_two_content"></div>
                    </div>
                    <div class="grid feature">
                        <!-- <img src="/img/feature-three.png"> -->
                        <div class="grid bill">
                            <div class="grid elipse"></div>
                            <div class="grid text">
                                <span style="font-size: 14px; margin-bottom: 4px;">Mario Rossi</span>
                                <span style="opacity: .7;">{{holiday.start}} - {{holiday.end}}</span>
                            </div>
                            <div class="grid icon">
                                <font-awesome-icon :icon="['fas', 'check']" />
                            </div>
                        </div>
                        <h2>{{content.Feature_three_title}}</h2>
                        <div v-html="content.Feature_three_content"></div>
                    </div>
                </div>


                <div class="grid">
                    <h2 style="text-align: center;">Questo ti aspetta</h2>
                    <div class="grid features deployed">
                        <div class="grid feature">
                            <div class="grid bill">
                                <div class="grid text">
                                    <span style="font-size: 14px; margin-bottom: 4px;">Multispiaggia</span>
                                    <span style="opacity: .7;">Spiagge illimitate</span>
                                </div>
                                <div class="grid icon">
                                    <font-awesome-icon :icon="['fas', 'check']" />
                                </div>
                            </div>
                        </div>
                        <div class="grid feature">
                            <div class="grid bill">
                                <div class="grid text">
                                    <span style="font-size: 14px; margin-bottom: 4px;">Stagioni detagliate</span>
                                    <span style="opacity: .7;">Massimo controllo della contabilitá</span>
                                </div>
                                <div class="grid icon">
                                    <font-awesome-icon :icon="['fas', 'check']" />
                                </div>
                            </div>
                        </div>
                        <div class="grid feature">
                            <div class="grid bill">
                                <div class="grid text">
                                    <span style="font-size: 14px; margin-bottom: 4px;">Segmentazione spiaggia</span>
                                    <span style="opacity: .7;">Variazione posti in spiaggia</span>
                                </div>
                                <div class="grid icon">
                                    <font-awesome-icon :icon="['fas', 'check']" />
                                </div>
                            </div>
                        </div>

                        <div class="grid feature">
                            <div class="grid bill">
                                <div class="grid text">
                                    <span style="font-size: 14px; margin-bottom: 4px;">Creazione QR</span>
                                    <span style="opacity: .7;">Per un Checkin veloce</span>
                                </div>
                                <div class="grid icon">
                                    <font-awesome-icon :icon="['fas', 'check']" />
                                </div>
                            </div>
                        </div>

                        <div class="grid feature">
                            <div class="grid bill">
                                <div class="grid text">
                                    <span style="font-size: 14px; margin-bottom: 4px;">App IOS e Android</span>
                                    <span style="opacity: .7;">Per la scansione del QR</span>
                                </div>
                                <div class="grid icon">
                                    <font-awesome-icon :icon="['fas', 'check']" />
                                </div>
                            </div>
                        </div>

                        <div class="grid feature">
                            <div class="grid bill">
                                <div class="grid text">
                                    <span style="font-size: 14px; margin-bottom: 4px;">Multiutenze</span>
                                    <span style="opacity: .7;">Per i tuoi collaboratori</span>
                                </div>
                                <div class="grid icon">
                                    <font-awesome-icon :icon="['fas', 'check']" />
                                </div>
                            </div>
                        </div>

                        <div class="grid feature">
                            <div class="grid bill">
                                <div class="grid text">
                                    <span style="font-size: 14px; margin-bottom: 4px;">Multipli Ombrelloni</span>
                                    <span style="opacity: .7;">Per gruppi e grandi famiglie</span>
                                </div>
                                <div class="grid icon">
                                    <font-awesome-icon :icon="['fas', 'check']" />
                                </div>
                            </div>
                        </div>

                        <div class="grid feature">
                            <div class="grid bill">
                                <div class="grid text">
                                    <span style="font-size: 14px; margin-bottom: 4px;">Broadcasting</span>
                                    <span style="opacity: .7;">Per mandare messaggi</span>
                                </div>
                                <div class="grid icon">
                                    <font-awesome-icon :icon="['fas', 'check']" />
                                </div>
                            </div>
                        </div>

                    </div>

                    <h3 style="text-align: center;">Questo tra breve</h3>
                    <div class="grid features nondeployed">

                        <div class="grid feature">
                            <div class="grid bill">
                                <div class="grid text">
                                    <span style="font-size: 14px; margin-bottom: 4px;">Magazzino</span>
                                    <span style="opacity: .7;">Per tutti gli Extra</span>
                                </div>
                                <div class="grid icon">
                                    <font-awesome-icon :icon="['fas', 'check']" />
                                </div>
                            </div>
                        </div>

                        <div class="grid feature">
                            <div class="grid bill">
                                <div class="grid text">
                                    <span style="font-size: 14px; margin-bottom: 4px;">Pagina dedicata</span>
                                    <span style="opacity: .7;">Per accettare prenotazioni</span>
                                </div>
                                <div class="grid icon">
                                    <font-awesome-icon :icon="['fas', 'check']" />
                                </div>
                            </div>
                        </div>

                    </div> 
                </div>

                <div class="grid cta">
                    <!-- <span class="primary"><a href="https://dashboard.ombrelleria.com" class="btn red lg">Provalo gratuitamente per tutto il 2021</a></span> -->
                    <span class="primary" @click="gevent('cta2')"><span class="btn red lg">Provalo gratuitamente per tutto il 2024</span></span>
                </div>

            </div>
        </div>

        <div class="grid functions" id="functions">
            <div class="grid block">
                <div class="grid innerblock">
                    <div class="grid text">
                        <h2>{{content.Seat_title}}</h2>
                        <div v-html="content.Seat_content"></div>
                    </div>
                </div>
                <div class="grid seat">
                    <!-- <img src="/img/block-seat.png"> -->
                    <div class="grid data">
                        <span class="ombrid">A1</span>
                        <span class="room">302</span>
                    </div>
                    <div class="grid items">
                        <span><img src="/img/beach-chair-light@2x.png"></span>
                        <span><img src="/img/beach-deckchair-light@2x.png"></span>
                    </div>
                    <div class="grid stay">
                        <span class="name">Mario Rossi</span>
                        <span class="date">{{holiday.start}} - {{holiday.end}}</span>
                    </div>
                </div>
                <div class="grid function one">
                    <span class="grid title">Setup ombrellone</span>
                    <!-- <img src="/img/function-one.png"> -->
                    <div class="grid item">
                        <span><img src="/img/beach-chair@2x.png"></span>
                        <span><img src="/img/beach-deckchair@2x.png"></span>
                        <!-- <div class="grid icon">
                            <font-awesome-icon :icon="['fas', 'user-astronaut']" />
                        </div>
                        <div class="grid text">
                            <span style="font-size: 16px; margin-bottom: 4px;">Mario Rossi</span>
                            <span style="opacity: .7;">{{holiday.start}} - {{holiday.end}}</span>
                        </div> -->
                    </div>
                </div>
                <div class="grid function two">
                    <span class="grid title">Informazioni prenotazione</span>
                    <!-- <img src="/img/function-two.png"> -->
                    <div class="grid item">
                        <div class="grid icon">
                            <font-awesome-icon :icon="['fas', 'user-astronaut']" />
                        </div>
                        <div class="grid text">
                            <span style="font-size: 16px; margin-bottom: 4px;">Mario Rossi</span>
                            <span style="opacity: .7;">{{holiday.start}} - {{holiday.end}}</span>
                        </div>
                    </div>
                </div>
                <div class="grid function three">
                    <span class="grid title">Dettagli aggiuntivi</span>
                    <!-- <img src="/img/function-three.png"> -->
                    <div class="grid item">
                        <div class="grid icon">
                            <font-awesome-icon :icon="['fas', 'key']" />
                        </div>
                        <div class="grid text">
                            <span style="font-size: 16px; margin-bottom: 4px;">Camera 302</span>
                            <span style="opacity: .7;">{{holiday.start}} - {{holiday.end}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="grid cta">
            <span class="primary" @click="gevent('cta3')"><span class="btn red lg">Provalo gratuitamente per tutto il 2024</span></span>
        </div>

        <div class="grid faq" id="faq">
            <div class="grid content">
                <div class="title">
                    <h2>Faq</h2>
                    <p>Le domande più frequenti</p>
                </div>
                <div class="grid quests">
                    <el-collapse v-model="activeName" accordion>
                    <el-collapse-item :title="faq.title" :name="index" v-for="(faq, index) in faqs" :key="index">
                        <!-- <div>{{faq.desc}}</div> -->
                        <div v-html="faq.desc"></div>
                    </el-collapse-item>
                    </el-collapse>
                </div>
            </div>
        </div>

        <div class="grid footer">
            <div class="content">
                <a href="https://dashboard.ombrelleria.com">
                    <div class="grid cta">
                        <div class="grid elipse">
                            <div class="grid text">
                                <h2>Non vendiamo Ombrelloni</h2>
                                <p>Ombrelleria è un'applicazione per gestire ombrelloni, sdraio e ogni tipologia di posto in spiaggia.</p>
                            </div>
                        </div>
                        
                    </div>
                </a>
                <div class="grid address">
                    <h3>Ombrelleria</h3>
                    <div class="grid imprint">
                        <span><small><a href="https://www.iubenda.com/privacy-policy/47610818" target="new">Privacy</a> & <a href="https://www.iubenda.com/privacy-policy/47610818/cookie-policy" target="new">Cookies</a></small></span>
                        <span @click="imprint.open = true"><small>Imprint</small></span>
                        <span><small><a href="mailto:info@ombrelleria.com">info@ombrelleria.com</a></small></span>
                    </div>
                </div>
            </div>
        </div>

        <el-dialog
          title="Imprint"
          :visible.sync="imprint.open"
          width="fit-content">
          <div>
            <address><strong>Ombrelleria</strong></address>
            <address>info@ombrelleria.com</address>
            <p><small>Ombrelleria é una applicazione prodotta da:</small></p>
            <address><strong>Switch</strong></address>
            <address>Via Principale 68</address>
            <address>39018 Terlano</address>
            <address>BZ - Italia</address>
            <address>IT02873000216</address>
            <address>support@superswitch.co</address>
            <p><small><a href="https://www.iubenda.com/privacy-policy/47610818" target="new">Privacy</a> & <a href="https://www.iubenda.com/privacy-policy/47610818/cookie-policy" target="new">Cookies</a></small></p>
          </div>
        </el-dialog>

    </div>
</template>

<script>
// @ is an alias to /src
import moment from 'moment'
import Navbar from '@/components/Navbar.vue'

export default {
  name: 'Home',
  data () {
    return {
        activeName: 0,
        imprint: {
            open: false
        },
        faqs: [],
        content: '',
        holiday: {
            start: moment().format('DD.MM.YYYY'),
            end: moment().add(7, 'd').format('DD.MM.YYYY')
        }
    }
  },
  components: {
    Navbar
  },
  methods: {
    gevent () {
        // window.ga('send', 'event', 'standard-event', 'tryout', cta)
        window.location.replace('https://dashboard.ombrelleria.com')
    },
    getContent () {
      const html = document.documentElement // returns the html tag
      html.setAttribute('lang', this.lang)
      this.$http
        .post('https://content.ombrelleria.com/api/singletons/get/ombrelleriaWS2021'
        ).then((data) => {
          // console.log('data loaded: ', data.body)
          // console.log('content loaded ', data.body)
          this.content = data.data
          this.loading = false
          // this.getDishes()
        }, (error) => {
          console.log('error: ', error)
          // this.fallback()
        })
    },
    getFaq () {
      this.$http
        .get('https://content.ombrelleria.com/api/collections/get/ombrelleria_faq?lang=' + this.lang
        ).then((data) => {
            // console.log(data)
          this.faqs = data.data.entries
          this.loading = false
        }, (error) => {
          console.log('error: ', error)
          this.fallback()
        })
    }
  },
  beforeMount () {
    this.getFaq()
    this.getContent()
    // if (process.env.NODE_ENV === 'production') {
    //     let iubendaSrc = document.createElement('script')
    //     iubendaSrc.setAttribute('src', 'https://cdn.iubenda.com/cs/iubenda_cs.js')
    //     document.head.appendChild(iubendaSrc)
    // }
  }
}
</script>
