import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import { Form, FormItem, DatePicker, TimeSelect, Input, Drawer, Loading, Collapse, CollapseItem, Dialog } from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import lang from 'element-ui/lib/locale/lang/it'
import locale from 'element-ui/lib/locale'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faAngleDown, faAngleRight, faTimes, faPizzaSlice, faPlusCircle, faCartPlus, faCheck, faSearch, faUserAstronaut, faKey } from '@fortawesome/free-solid-svg-icons'
import { faFacebookSquare, faInstagram } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import VueScrollTo from 'vue-scrollto'
// import VueAnalytics from 'vue-analytics'
// import VueGtag from "vue-gtag"

Vue.config.productionTip = false
Vue.use(VueScrollTo)

library.add(faAngleDown, faAngleRight, faTimes, faPizzaSlice, faPlusCircle, faCartPlus, faFacebookSquare, faInstagram, faCheck, faSearch, faUserAstronaut, faKey)
locale.use(lang)

Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.config.productionTip = false
Vue.use(Form)
Vue.use(FormItem)
Vue.use(DatePicker, { locale })
Vue.use(TimeSelect)
Vue.use(Input)
Vue.use(Loading)
Vue.use(Collapse)
Vue.use(CollapseItem)
Vue.use(Drawer)
Vue.use(Dialog)
// Vue.use(VueAnalytics, {
//   id: 'UA-124191030-1',
//   router,
//   beforeFirstHit () {
//     Vue.$ga.set('anonymizeIp', true)
//   }
// })
// Vue.use(VueGtag, {
//   config: { id: "G-15SNE7V1RE" }
// })

new Vue({
  router,
  store,
  render: h => h(App)
 //  created () {
	// let iubendaSrc = document.createElement('script')
 //    iubendaSrc.setAttribute('src', 'https://cdn.iubenda.com/cs/iubenda_cs.js')
 //    document.head.appendChild(iubendaSrc)
 //  }
}).$mount('#app')
